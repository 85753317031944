import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/logo.jpeg";
import facebook from '../Assets/icons8-facebook-50.png'
import linkin from '../Assets/icons8-in-50.png'
import insta from '../Assets/icons8-instagram-50.png'
import { Link } from "react-scroll";

const Footer = () => {
  const instagaramlink ='https://www.instagram.com/penstop.co?igsh=MXd6NGJ3bjFxbWg0OA==';
  const facebookLink = 'https://www.facebook.com/khanbappi011';
  const linkedinLink = 'https://www.linkedin.com/in/zain-khan-641b8b224?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'; 
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);        
  }
  return (
    <>
    <footer class="color">
    <div class="mx-auto color max-w-5xl px-4  sm:px-6 lg:px-8">
      <div class="flex justify-center color text-teal-600">
        <img className="max-h-30  w-20 color" src={logo} alt="" />
      </div>
      <ul class="mt-12 flex flex-wrap justify-center color gap-6 md:gap-8 lg:gap-12">
        <li>
        <Link
                to="/"
                smooth={true}
                duration={500}
                onClick={routeChange}
                class="text-white transition hover:text-black text-2xl color cursor-pointer"
             >
                
           HOME 
              </Link>
        </li>
  
        <li>
        <Link
                to="how-it-works"
                smooth={true}
                duration={500}
                class="text-white transition hover:text-black text-2xl color cursor-pointer"   
              >
                
          TESTIMONIALS 
              </Link>
        </li>
  
        <li>
        <Link
                to="testimonials"
                smooth={true}
                duration={500}
                class="text-white transition hover:text-black text-2xl color cursor-pointer"      
              >
                
           WHITE LABLE SOLUTIONS 
              </Link>
        </li>
  
        <li>
        <Link
                to="Results"
                smooth={true}
                duration={500}
                class="text-white transition hover:text-black text-2xl color cursor-pointer"   
              >
                
           RESULTS 
              </Link>
        </li>
  
        
      </ul>
  
      <ul class="mt-12 flex justify-center gap-6 md:gap-8 color">
        <li>
          <a
            href={facebookLink}
            rel="noreferrer"
            target="_blank"
            class="text-gray-700 transition hover:text-gray-700/75"
          >
            <span class="sr-only color">Facebook</span>
           <img src={facebook} alt="facebook" className="h-10 w-10 color" />
          </a>
        </li>
  
        <li>
          <a
            href={instagaramlink}
            rel="noreferrer"
            target="_blank"
            class="text-gray-700 transition hover:text-gray-700/75"
          >
            <span class="sr-only">Instagram</span>
              <img src={insta} alt="intafram"  className="h-10 color w-10"/>
          </a>
        </li>
  
        <li>
            <a
              href={linkedinLink}
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition hover:text-gray-700/75"
            >
              <span className="sr-only color">LinkedIn</span>
              <img src={linkin} alt="LinkedIn" className="h-10 color text-white w-10" />
            </a>
          </li>

  
      
      </ul>
    </div>
  </footer>
      <footer class=" text-center text-white color lg:text-left">
  <div class="bg-black/5 p-4 text-center text-surface dark:text-white">
    © 2023 Copyright:
    
  </div>
</footer>
</>
  );
};

export default Footer;
