import React from "react";
import Header from "../../Components/Header";
import Hero from "../../Components/Hero";
import Autopiot from "../../Components/Autopiot";
import Footer from "../../Components/Footer";
import Customer from "../../Components/Customer";
import Result from "../../Components/Result";
import Profile from "../../Components/Profile";

const Home = () => {
  
  return (
    <div>
      <Header   />
      <Hero />   
      <Autopiot/>
      <Customer />
      <Result/>
      <Profile />
      <Footer/>
    </div>
  );
};

export default Home;
