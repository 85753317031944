import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from "../Assets/profile-image.jpeg";
import pic1 from "../Assets/Case study 1.jpg";
import pic2 from "../Assets/Case study 2.jpg";
import pic3 from "../Assets/Case study 3.jpg";
import "./Style.css";

const Profile = ({id}) => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef();
  const today = new Date().toISOString().split("T")[0];
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm(
      "service_9zbdn7k",
      "template_effcv7q",
      form.current,
      "zRopgvycRhOT-BKJZ"
    )
      .then(
        () => {
          toast.success("SUCCESS!");
          toggleModal(); // Close the modal after successful submission
        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("FAILED...");
        }
      );
  };

  let navigate1 = useNavigate(); 
  const routeChange1 = () =>{ 
    let path = `/Casestudy1`; 
    navigate1(path);        
  }
  let navigate2 = useNavigate(); 
  const routeChange2 = () =>{ 
    let path = `/Casestudy2`; 
    navigate2(path);        
  }
  let navigate3 = useNavigate(); 
  const routeChange3 = () =>{ 
    let path = `/Casestudy3`; 
    navigate3(path);        
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center space-y-2 py-10">
        <p className="text-xl text-gray-600">Case Studies</p>
        <h1 className="text-4xl font-bold text-gray-900">
          Check out our case studies
        </h1>
        <h6 className="font-medium text-gray-500">
          Some of the most recent results we've achieved for clients.
        </h6>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Case Study 1</h3>
          <img
            src={pic3}
            alt=""
            className="w-full object-cover h-64 rounded-lg"
          />
          <h1 className="text-lg font-bold text-blue-500">
            16 Applicants/ week for Financing
          </h1>
          <button
            type="button"
            className="mt-4 px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-700 transition duration-150"
            onClick={routeChange1}
          >
            See Case Study
          </button>
        </div>
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Case Study 2</h3>
          <img
            src={pic1}
            alt=""
            className="w-full object-cover h-64 rounded-lg"
          />
          <h1 className="text-lg font-bold text-blue-500">
            Detailed Market Research Service For Shopify Sellers
          </h1>
          <button
            type="button"
            className="mt-4 px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-700 transition duration-150"
            onClick={routeChange2}
          >
            See Case Study
          </button>
        </div>
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Case Study 3</h3>
          <img
            src={pic2}
            alt=""
            className="w-full object-cover h-64 rounded-lg"
          />
          <h1 className="text-lg font-bold text-blue-500">
            Achieving 48 Qualified Appointments within the ...
          </h1>
          <button
            type="button"
            className="mt-4 px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-700 transition duration-150"
            onClick={routeChange3}
          >
            See Case Study
          </button>
        </div>
      </div>
      <div id="about-us" className="mx-auto w-full md:w-10/12 mt-10 md:mt-44 py-5 mb-44 rounded-lg bg-white px-4 shadow-lg">
        <h1 className="text-center text-4xl md:text-7xl font-light bg-white mb-10">
          ABOUT US!
        </h1>
        <div className="profile-img bg-white">
          <img src={img} alt="Profile" className="w-full" />
        </div>
        <h1 className="text-center bg-white mt-5">ZAIN KHAN</h1>
        <h1 className="text-center bg-white font-light text-blue-600">
          Founder & CEO
        </h1>
        <p className="text-center bg-white">
          "I'm Zain Khan, an email marketing specialist with a passion for
          helping businesses achieve their marketing goals through targeted
          email campaigns. With 2 years of experience, I possess expertise in
          platforms like Instantly.ai and am proficient in crafting compelling
          email copy, designing effective templates, and implementing
          data-driven strategies."
        </p>
        <div>
          <div className="flex justify-center mt-5 bg-white">
            <button
              className="bg-blue-500 rounded mt-5 md:mt-0 hover:bg-blue-600 text-white font-bold py-3 px-6 md:py-2 md:px-3 lg-mt-0"
              onClick={toggleModal}
            >
              Book A Meeting
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed bg-white z-10 inset-0 overflow-y-auto flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white w-3/4 h-4/5 rounded-lg shadow-xl overflow-y-auto">
            <div className="flex bg-white justify-end px-4 py-2">
              <button
                type="button"
                onClick={toggleModal}
                className="text-gray-500 bg-white hover:text-gray-700"
              >
                <svg
                  className="h-6 w-6 bg-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-2 bg-white">
              <h2 className="text-2xl italic bg-white font-semibold mb-4 text-center text-color">
                Book A Meeting
              </h2>
              <div className="flex items-center justify-center bg-white mb-6">
                <div className="h-24 w-24 rounded-full bg-white overflow-hidden">
                  <img
                    src={img}
                    alt="Profile"
                    className="object-cover bg-white h-full w-full"
                  />
                </div>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="grid bg-white grid-cols-2">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_name"
                      className="block font-medium mb-2 bg-white dark:text-white"
                    >
                      NAME *
                    </label>
                    <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      className="py-3 bg-white px-4 border block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_email"
                      className="block font-medium mb-2 dark:text-white bg-white "
                    >
                      EMAIL *
                    </label>
                    <input
                      type="email"
                      id="user_email"
                      name="user_email"
                      className="py-3 px-4 block w-full border-gray-200 bg-white border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                      aria-describedby="hs-input-helper-text"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 bg-white">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="company_name"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      COMPANY NAME
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      
                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="meeting_date"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      MEETING DATE *
                    </label>
                    <input
                      type="date"
                      id="meeting_date"
                      name="meeting_date"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      min={today}
                      required
                    />
                  </div>
                </div>
                <div className="p-4 w-full bg-white">
                  <label
                    htmlFor="textarea-label"
                    className="block text-sm font-medium mb-2 dark:text-white bg-white"
                  >
                    Is there anything you'd like us to know before our scheduled meeting?
                  </label>
                  <textarea
                    id="textarea-label"
                    name="message"
                    className="py-3 bg-white border px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 bg-white"
                    rows="1"
                    placeholder="Say Everything..."
                  ></textarea>
                </div>
                <div className="mt-4 flex justify-end bg-white w-full">
                  <button
                    type="submit"
                    value="Send"
                    className="bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
