import React from 'react'
import Header from '../../Components/Header';
import casestudy2 from "../../Assets/casStudy2.png";
import rply1 from '../../Assets/rply1.png'
import rply2 from '../../Assets/rply2.png'
import goingnow from '../../Assets/goingnow.png'
import './casestudy2.css'
import Footer from '../../Components/Footer';

const CaseStudy2 = () => {
  return (
   <>
   <Header/>
    <div className="parent-container">
        <div className="page-content-case-study-1" >
          <h1>Case Study2:</h1>
          <h1 className='text-5xl text-cyan-600'>Detailed Market Research Service For Shopify Sellers</h1>
          <h3>Introduction</h3>
          <p>
          We have established a partnership with this client as a white-label service. One of our partners' clients specializes in offering comprehensive market research services to companies generating over 100k-250k USD annually. This esteemed organization holds a prominent position in India and sought to cater to companies in the Indian market that have a genuine need for thorough market research. With ticket sizes ranging from $3,000 to $7,000, capturing this premium offering posed a challenge.
          </p>
          <h3>Challenges:</h3>
          <ul>
            <li>
         <b>Targeting High-Budget Companies in India:</b> Our initial challenge revolved around identifying the ideal target audience. We needed to pinpoint companies with substantial budgets, need market research, a strong growth orientation, and operations within India.
            </li>
            <li>
            <b> Limited Email Sending Capacity:</b> With a maximum email sending capacity of 240 emails per month, we faced constraints in testing unique offers and pursuing significant ticket sales like the one in question.
            </li>
            <li>
          <b>New to Email Lead Generation:</b>   This was the client's first time using email for lead generation. They expected appointments from day one, which made rigorous A/B testing difficult. We had to strike a balance between their expectations and effective strategies.
            </li>
          </ul>
          <h3>Strategy And Solution:</h3>
          <ul>
            <li>
            High Budget industry: After weeks of research, we agreed to target Indian Amazon Sellers, because they are heavily invested, and need crucial market research to make their product stand out from the competitors. But we faced a different problem, it is too difficult to acquire information from Amazon sellers almost impossible, so we instead proceeded with Shopify, so both the industry are the same from our perspective.
            </li>
            <li>
            Leads quality and specific targeting: We scraped the best leads in the best manner we could possibly to decrease the chances of reaching out to irrelevant or non-decision-maker leads because we have a low sending volume, so we need to be more than the best in terms of deliverability.
            </li>
            <li>
            Daily Discussion: We held team discussions daily for more than an hour discussing the improvements and checking every box to ensure the perfect launch..
            </li>
          </ul>
          <h2>Results:</h2>
          <img src={casestudy2} alt="" />
          <p>
          After just 2 weeks of initiating our cold outreach, we observed promising results from 3 campaigns. Each campaign comprised 220 emails, achieving an impressive open rate of over 78%. Additionally, the reply rate was 5%, translating to 27 positive responses. Among these, 40% proceeded to book a call.
          </p>
          <p>Some Replies that we got from well-known brands in India :
          </p>
         
          <img src={rply1} alt="" />
          <img src={rply2} alt="" />

          <h6>
          How Its Going Now:
          </h6>
          <img src={goingnow} alt="" />
          <h1>We're continuing to achieve consistent results, and our dedication to improvement shines through as we experiment with various copywriting approaches. Our strategic copywriting and unwavering commitment to our goals make all of this progress possible</h1>
        </div>
      </div>

     <Footer/>
   
   
   </>
  )
}

export default CaseStudy2
