import React from 'react'
import Header from '../../Components/Header';
import casestudy from "../../Assets/Pin-pic-2.png";
import result6 from "../../Assets/Result 6.png";
import notification1 from "../../Assets/Notification 1 (1).png";
import notification2 from "../../Assets/Notification2.png";
import notification5 from "../../Assets/Notification 5.png";
import notification3 from "../../Assets/Notification 3.png";
import notification4 from "../../Assets/Notification 4.png";
import './casestudy1.css'
import Footer from '../../Components/Footer';

const CaseStudy1 = () => {
  return (
   <>
   <Header/>
    <div className="parent-container">
        <div className="page-content-case-study-1" >
          <h1>Case Study1:</h1>
          <h1 className='text-5xl text-cyan-600'>16 Applicants/ week for Financing</h1>
          <h3>Introduction</h3>
          <p>
            We partnered with two finance companies to help them secure loan
            applicants on a weekly basis. They aimed to target roofing, HVAC,
            and similar industries. While this might seem straightforward, it
            posed challenges due to high interest rates and the need for
            reliable financial solutions.
          </p>
          <h3>Challenges:</h3>
          <ul>
            <li>
              <b>Dual Company Collaboration:</b>Working with two companies
              simultaneously, offering the same services to the same industries
              within a short timeframe, provided us with a unique A&B testing
              opportunity. We could test multiple approaches in a compressed
              time period.
            </li>
            <li>
              <b>Encouraging Application Submissions:</b>An apparent challenge
              emerged when even interested prospects hesitated to fill out the
              application form. Despite the simplicity of the application, we
              needed to address reluctance and follow up effectively.
            </li>
          </ul>
          <h3>Strategy And Solution:</h3>
          <ul>
            <li>
              Conducting extensive market research, understanding prevailing
              interest rates and daily team discussions gave us a partial
              understanding of the scenario.
            </li>
            <li>
              We initially developed over 15 different initial email templates.
              After refining our approaches further, we narrowed it down to 6
              distinct templates. Out of these, we planned to utilize 3 per
              company, tracking which ones yielded better results. These
              templates varied based on our goals: some aimed at garnering
              applicants, some at scheduling meetings, and others at simply
              capturing attention.
            </li>
            <li>
              Additionally, we diversified our focus by targeting multiple
              niches. We scraped data for 5,000 HVAC, 5,000 Transportation, and
              5,000 Roofing leads. At this stage, our primary aim was to gather
              data from the A&B test. This data would serve as the foundation
              for generating consistent and impactful results moving forward.
            </li>
          </ul>
          <h2>Results:</h2>
          <h4>First Client:</h4>
          <p>
            This is the campaign result from the first client after a complete
            sequence from a single campaign.
          </p>
          <img src={casestudy} alt="" />
          <p>
            We generated 85+ open rates, a 6.7% Reply rate, and got 9
            applicants/booked meetings after confirming the loan amount of these
            leads, we generated a total of 760,000 in revenue for this client..
          </p>
          <h4>Second Client:</h4>
          <p>
            This is the campaign result from the first client after a complete
            sequence from a single campaign.
          </p>
          <img src={result6} alt="" />
          <p>And Here are some Replies that we got,</p>
          <img src={notification1} alt="" />
          <img src={notification2} alt="" />
          <img src={notification3} alt="" />
          <img src={notification4} alt="" />
          <img src={notification5} alt="" />

          <h6>
            Up until now, we are helping them generate $1M+ rev per month while
            still A&B testing to improve and hit our next $5M Milestone by
            leveraging volume, improving CRM, and scrapping the right audience.
          </h6>
        </div>
      </div>

     <Footer/>
   
   
   </>
  )
}

export default CaseStudy1
