import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emmailBanner from '../Assets/E-mail.jpg'
import img from "../Assets/profile-image.jpeg";
import './Style.css'
const Hero = () => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef();
  const today = new Date().toISOString().split("T")[0];
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm(
      "service_9zbdn7k",
      "template_effcv7q",
      form.current,
      "zRopgvycRhOT-BKJZ"
    )
      .then(
        () => {
          toast.success("SUCCESS!");
          toggleModal(); // Close the modal after successful submission
        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("FAILED...");
        }
      );
  };





  return (
    <>
    
    <div class="w-full  h-96 md:flex flex-row flex-wrap flex-grow gray-color Hero-section ">
            <div class=" w-10/12 m-auto flex  flex-wrap">
                <div class="w-full sm:w-1/2 ">
                  
                  <div class="h-96 pt-12 sm:h-64"><h1 class="text-5xl   font-extralight text-blue-600 sm:mt-20">Elevate your Sales on Autopilot.</h1>
                    <h6 class="text-2xl mt-5">Partner with us to generate consistent flow of leads and
                      outperform competitors.</h6>
                      <button class="bg-blue-500 hover:bg-blue-700 text-white mt-5 font-bold py-2 px-6 rounded-full" onClick={toggleModal }>
                        Book A Call
                      </button>
                    </div>
                </div>
                <div class="w-full  sm:w-1/2">
                  
                  <div class="h-32  sm:h-96">
                    <div class="flex justify-center items-center py-11">
                        <img src={emmailBanner} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        {showModal && (
        <div className="fixed bg-white z-10 inset-0 overflow-y-auto flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white w-3/4 h-4/5 rounded-lg shadow-xl overflow-y-auto">
            <div className="flex bg-white justify-end px-4 py-2">
              <button
                type="button"
                onClick={toggleModal}
                className="text-gray-500 bg-white hover:text-gray-700"
              >
                <svg
                  className="h-6 w-6 bg-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-2 bg-white">
              <h2 className="text-2xl italic bg-white font-semibold mb-4 text-center text-color">
                Book A Meeting
              </h2>
              <div className="flex items-center justify-center bg-white mb-6">
                <div className="h-24 w-24 rounded-full bg-white overflow-hidden">
                  <img
                    src={img}
                    alt="Profile"
                    className="object-cover bg-white h-full w-full"
                  />
                </div>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="grid bg-white grid-cols-2">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_name"
                      className="block font-medium mb-2 bg-white dark:text-white"
                    >
                      NAME *
                    </label>
                    <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      className="py-3 bg-white px-4 border block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_email"
                      className="block font-medium mb-2 dark:text-white bg-white "
                    >
                      EMAIL *
                    </label>
                    <input
                      type="email"
                      id="user_email"
                      name="user_email"
                      className="py-3 px-4 block w-full border-gray-200 bg-white border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                      aria-describedby="hs-input-helper-text"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 bg-white">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="company_name"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      COMPANY NAME
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"

                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="meeting_date"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      MEETING DATE *
                    </label>
                    <input
                      type="date"
                      id="meeting_date"
                      name="meeting_date"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      min={today}
                      required
                    />
                  </div>
                </div>
                <div className="p-4 w-full bg-white">
                  <label
                    htmlFor="textarea-label"
                    className="block text-sm font-medium mb-2 dark:text-white bg-white"
                  >
                    Is there anything you'd like us to know before our scheduled meeting?
                  </label>
                  <textarea
                    id="textarea-label"
                    name="message"
                    className="py-3 bg-white border px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 bg-white"
                    rows="1"
                    placeholder="Say Everything..."
                  ></textarea>
                </div>
                <div className="mt-4 flex justify-end bg-white w-full">
                  <button
                    type="submit"
                    value="Send"
                    className="bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Hero
