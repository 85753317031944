import React from 'react'
import Header from '../../Components/Header';
import image1 from "../../Assets/Result 3.png";
import image2 from "../../Assets/casestudypg2.png";
import image3 from "../../Assets/casestudy3-3.png";

import './casestudy3.css'
import Footer from '../../Components/Footer';

const CaseStudy3 = () => {
  return (
   <>
   <Header/>
    <div className="parent-container">
        <div className="page-content-case-study-1 " >
          <h1>Case Study3:</h1>
<h1 className='text-5xl text-cyan-600'>Achieving 48 Qualified Appointments within the First Month</h1>
          <h3>Introduction</h3>
          <p>
          We partnered with an M&A consultant agency to help them connect with e-commerce stores generating 5M-10M/year in revenue. While the plan was clear, it turned out to be more challenging due to our limited experience in this industry.
          </p>
          <h3>Challenges:</h3>
          <ul>
            <li>
            We started by researching the market, target audience, and pain points. However, a new challenge arose as the clients' inboxes were already active, and they wanted the outreach to start immediately. This left us just one week to conduct research, gather leads, and create effective email copies.
            </li>
            <li>
            Our target was large companies, and gaining the trust of decision-makers was difficult. We needed an approach that felt like an opportunity rather than a sales pitch. Although the client initially aimed at digital marketing agencies, the primary focus was on e-commerce. This limited our room for extensive testing
            </li>
          </ul>
          <h3>Strategy And Solution:</h3>
          <ul>
            <li>
            At this point, our research was done, and we developed a unique copywriting approach. The client provided insights for the copy, helping us craft a "One-Line Email" which we called the "Opportunity Mail." This email aimed to initiate a conversation, making trust-building easier.
            </li>
            <li>
            Our initial outreach focused on digital marketing agencies due to time constraints. Our approach wasn't strictly conventional, but it was effective in sparking conversations.
Results:
            </li>
          </ul>
          <h2>Results:</h2>
        
        
          <img src={image1} alt="" />
          <p>
          From this campaign, we secured 13 qualified meetings. The sequence included an initial email and a follow-up (with additional follow-ups planned).
          </p>
          <p>
          And here is a sneak pic of the structure of the copy we used.
          </p>
          <img src={image2} alt="" />
          <p>Later, we extended our outreach to e-commerce stores, yielding remarkable results:</p>
          <img src={image3} alt="" />
          

          <h1>
          For this campaign, we generated 20+ meetings with a 7% reply rate and approximately 85% open rates.
          </h1>
        </div>
      </div>

     <Footer/>
   
   
   </>
  )
}

export default CaseStudy3
