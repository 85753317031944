import React from "react";

const Autopilot = ({id}) => {
  return (
    <div id="how-it-works" className="w-full gray-color mt-96 sm:mt-20">
      <div className="w-10/12 m-auto">
      <h1 className="text-3xl font-semibold text-gray-800 text-center" style={{ marginTop: '290px' }}>How Our Autopilot Model Works</h1>
      </div>
      <div className="flex flex-wrap justify-center mt-20">
        <div className="w-10/12 text-center sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full  text-center flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">1</h6>
          </div>
          <div className="">
            <h5 className="text-blue-700  text-4xl">Step</h5>
          </div>
          <p className="">
            We Link Your Domains to the Warm-up Process, Preparing Them for Successful Mass Email Delivery.
          </p>
        </div>

        <div className="w-10/12 text-center sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full  text-center flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">2</h6>
          </div>
          <div>
            <h5 className="text-blue-700 text-4xl ">Collecting Data</h5>
          </div>
          <p className="">
            During the 2-week domain warm-up, we immerse ourselves in your industry, identifying your target audience's pain points and collecting their valuable contact information.
          </p>
        </div>

        <div className="w-10/12 text-center h-60 sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full  text-center flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">3</h6>
          </div>
          <div>
            <h5 className="text-blue-700 text-4xl ">Email Scripts</h5>
          </div>
          <p className="">
            We Skillfully Craft Persuasive scripts that Intrigue that captures attention leading to Meaningful Conversations.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center mt-5">
        <div className="w-10/12 text-center sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full text-center  flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">5</h6>
          </div>
          <div>
            <h5 className="text-blue-700 text-4xl ">Outreach</h5>
          </div>
          <p className="">
            Now we start sending these emails to the prospects while analyzing performance and efficiently managing replies.
          </p>
        </div>
        <div className="w-10/12 text-center sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full  text-center flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">1</h6>
          </div>
          <div>
            <h5 className="text-blue-700 text-4xl ">Nurturing Leads</h5>
          </div>
          <p className="">
            Here we Engage with Interested Prospects, Highlighting the Benefits of Your Service and Nurturing them toward a Meeting.
          </p>
        </div>
        <div className="w-10/12 h-60 text-center sm:w-1/2 md:w-1/3 lg:w-2/6 p-4  shadow-md rounded-md mb-4 flex justify-center flex-wrap items-center">
          <div className="w-full  text-center flex justify-center items-center">
            <h6 className="text-white bg-slate-500 text-2xl flex justify-center items-center h-10 w-10 rounded-full">1</h6>
          </div>
          <div>
            <h5 className="text-blue-700 text-4xl ">Booked Meetings</h5>
          </div>
          <p className="">
            We Secure these Meetings in Your Calendar, Enabling Your Team to Excel at Closing the Deal.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Autopilot;
