import './App.css';
import Home from './pages/Home/Home';
import CaseStudy1 from './pages/Casestudy1/CaseStudy1'; 
import CaseStudy2 from './pages/Casestudy2/CaseStudy2'; 
import CaseStudy3 from './pages/Casestuy3/CaseStudy3'; 
import { BrowserRouter, Routes, Route ,Navigate} from 'react-router-dom'; 

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/Casestudy1" element={<CaseStudy1 />} />
          <Route path="/Casestudy2" element={<CaseStudy2 />} />
          <Route path="/Casestudy3" element={<CaseStudy3 />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
