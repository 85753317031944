import React from "react";

const Customer = ({ id }) => {
  return (
    <>
      <div id="testimonials" className="gray-color w-full mt-20">
        <div className="w-10/12 m-auto">
          <h1 className="text-3xl font-semibold text-gray-800 text-center">
            What Our Clients Think About Us!
          </h1>
          <div className="mx-auto text-center md:max-w-xl lg:max-w-3xl"></div>

          <div className="grid gap-6 text-center mt-20 md:grid-cols-3 lg:gap-12">
            <div className="mb-12 md:mb-0">
              <div className="mb-6 flex justify-center">
                <img
                  src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(1).jpg"
                  className="w-32 rounded-full shadow-lg dark:shadow-black/30"
                  alt="Customer Avatar"
                />
              </div>
              <h5 className="mb-4 text-xl font-semibold">Maria Smantha</h5>
              
              <p className="mb-4 text-black">
                <span className="inline-block pe-2 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                  </svg>
                </span>
                I experienced a noteworthy return on investment with Zayn Email
                Marketing Services. Their expertise helped amplify my business's
                success, resulting in tangible benefits. I am confident in
                recommending their services to other individuals looking to
                achieve similar results.
              </p>
              <ul className="mb-0 flex items-center justify-center">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
            </div>

            {/* Additional testimonial cards */}

            <div className="mb-12 md:mb-0">
              <div className="mb-6 flex justify-center">
                <img
                  src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(2).jpg"
                  className="w-32 rounded-full shadow-lg dark:shadow-black/30"
                  alt="Customer Avatar"
                />
              </div>
              <h5 className="mb-4 text-xl font-semibold">Iris west</h5>

              <p className="mb-4 text-black">
                <span className="inline-block pe-2 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                  </svg>
                </span>
                I received exceptional support. Their team
            offered personalized guidance and assistance, ensuring
            that my unique needs were met. I truly
            appreciate their dedication and would recommend their services to
            anyone seeking individualized attention.
              </p>
              <ul className="mb-0 flex items-center justify-center">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
            </div>

            {/* Another testimonial card */}

            <div className="mb-12 md:mb-0">
              <div className="mb-6 flex justify-center">
                <img
                  src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(3).jpg"
                  className="w-32 rounded-full shadow-lg dark:shadow-black/30"
                  alt="Customer Avatar"
                />
              </div>
              <h5 className="mb-4 text-xl font-semibold">John Doe</h5>
              
              <p className="mb-4 text-black">
                <span className="inline-block pe-2 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                  </svg>
                </span>
                I found this Services to be incredibly
            effective. Their tailored approach resulted in remarkable
            outcomes for my endeavors. I wholeheartedly
            endorse their services to fellow individuals seeking impactful email
            marketing solutions.
              </p>
              <ul className="mb-0 flex items-center justify-center">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-yellow-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.314l-4.183 2.094.799-4.658-3.384-3.29 4.69-.683L12 6.369l1.078 4.309 4.69.683-3.384 3.29.799 4.658z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
