import React, { useState, useRef } from "react";
import { Link } from "react-scroll";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../Assets/logo.jpeg";
import { useNavigate } from "react-router-dom";
import img from "../Assets/profile-image.jpeg";
import "./Style.css";

const Header = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef();
  const today = new Date().toISOString().split("T")[0];

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9zbdn7k",
        "template_effcv7q",
        form.current,
        "zRopgvycRhOT-BKJZ"
      )
      .then(
        () => {
          toast.success("SUCCESS!");
          toggleModal(); // Close the modal after successful submission
        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("FAILED...");
        }
      );
  };
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);        
  }

  

  return (
    <>
      <div className="w-full fixed  color ">
        <nav className="flex items-center w-10/12 m-auto  justify-between flex-wrap color p-1.5">
          <div className="flex items-center flex-shrink-0 c text-white mr-6">
            <Link to="/home" smooth={true} duration={500} onClick={routeChange}>
              <img className="w-16 h-16  cursor-pointer " src={logo} alt=""  />
            </Link>
          </div>
          <div className="block color lg:hidden">
            <button className="flex items-center px-1.5 py-2 border rounded border-teal-400 lg:text-sm hover:text-black  hover:border-white">
              <svg
                className="fill-current  h-3 w-3 cursor-pointer"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div className="w-full color m-auto block flex-grow lg:flex lg:items-center lg:w-auto justify-center">
            <div className="text-sm color sm:flex-grow xl:flex xl:justify-center">
              <Link
                to="/"
                smooth={true}
                duration={500}
                className="block color lg:inline-block cursor-pointer lg:mt-0 text-white  hover:text-black  lg:text-lg mr-10 md:text-sm"
                onClick={routeChange}>
                Home
              </Link>
              <Link
                to="testimonials"
                smooth={true}
                duration={500}
                className="block color lg:inline-block cursor-pointer lg:mt-0 text-white  hover:text-black  lg:text-lg mr-10 md:text-sm"
              >
                Testimonials
              </Link>
              <Link
                to="how-it-works"
                smooth={true}
                duration={500}
                className="block color lg:inline-block cursor-pointer lg:mt-0 text-white  hover:text-black  lg:text-lg mr-10 md:text-sm"
              >
                How it Works
              </Link>
              <Link
                to="about-us"
                smooth={true}
                duration={500}
                className="block color lg:inline-block cursor-pointer lg:mt-0 text-white  hover:text-black  lg:text-lg mr-10 md:text-sm"
              >
                About Us
              </Link>
            </div>
            <div className="color">
              <button
                className="bg-blue-500 hover:bg-blue-600 cursor-pointer rounded-full text-white  font-bold lg-mt-0 py-2 px-6 rounded"
                onClick={toggleModal}
              >
                Book A Call
              </button>
            </div>
          </div>
        </nav>
      </div>
      {showModal && (
        <div className="fixed bg-white z-10 inset-0 overflow-y-auto flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white w-3/4 h-4/5 rounded-lg shadow-xl overflow-y-auto">
            <div className="flex bg-white justify-end px-4 py-2">
              <button
                type="button"
                onClick={toggleModal}
                className="text-gray-500 bg-white hover:text-gray-700"
              >
                <svg
                  className="h-6 w-6 bg-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-2 bg-white">
              <h2 className="text-2xl italic bg-white font-semibold mb-4 text-center text-color">
                Book A Meeting
              </h2>
              <div className="flex items-center justify-center bg-white mb-6">
                <div className="h-24 w-24 rounded-full bg-white overflow-hidden">
                  <img
                    src={img}
                    alt="Profile"
                    className="object-cover bg-white h-full w-full"
                  />
                </div>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="grid bg-white grid-cols-2">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_name"
                      className="block font-medium mb-2 bg-white dark:text-white"
                    >
                      NAME *
                    </label>
                    <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      className="py-3 bg-white px-4 border block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="user_email"
                      className="block font-medium mb-2 dark:text-white bg-white "
                    >
                      EMAIL *
                    </label>
                    <input
                      type="email"
                      id="user_email"
                      name="user_email"
                      className="py-3 px-4 block w-full border-gray-200 bg-white border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      required
                      aria-describedby="hs-input-helper-text"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 bg-white">
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="company_name"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      COMPANY NAME
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      
                    />
                  </div>
                  <div className="col-span-1 bg-white p-4">
                    <label
                      htmlFor="meeting_date"
                      className="block text-sm font-medium mb-2 dark:text-white bg-white"
                    >
                      MEETING DATE *
                    </label>
                    <input
                      type="date"
                      id="meeting_date"
                      name="meeting_date"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 bg-white border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      min={today}
                      required
                    />
                  </div>
                </div>
                <div className="p-4 w-full bg-white">
                  <label
                    htmlFor="textarea-label"
                    className="block text-sm font-medium mb-2 dark:text-white bg-white"
                  >
                    Is there anything you'd like us to know before our scheduled meeting?
                  </label>
                  <textarea
                    id="textarea-label"
                    name="message"
                    className="py-3 bg-white border px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 bg-white"
                    rows="1"
                    placeholder="Say Everything..."
                  ></textarea>
                </div>
                <div className="mt-4 flex justify-end bg-white w-full">
                  <button
                    type="submit"
                    value="Send"
                    className="bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
