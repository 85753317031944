import React from "react";
import pic1 from "../Assets/Pin-pic-1.png";
import pic2 from "../Assets/Pin-pic-2.png";
import pic3 from "../Assets/Pin-pic-3.png";
import logo1 from '../Assets/Banter logo.png'
import logo2 from '../Assets/Katexs Logo.png'
import logo3 from '../Assets/Like boss logo.jpg'
import logo4 from '../Assets/emiko Logo.png'
import logo5 from '../Assets/RFR-removebg-preview.png'
import logo6 from '../Assets/White glove logo.png'

const Result = () => {
  return (
    <>
      <div id="Results" className="text-center py-12 ">
        <h1 className="text-3xl font-semibold text-gray-800">Results That Speak For Themselves!</h1>
      </div>

      <div className="flex flex-wrap justify-center gap-10 px-4 mb-12">
        <div className="w-full md:w-1/3 text-center">
          <img src={pic3} alt="" className="mx-auto" />
          <span className="block mt-4">Present it as an opportunity rather than an offer.</span>
        </div>
        <div className="w-full md:w-1/3 text-center">
          <img src={pic2} alt="" className="mx-auto" />
          <span className="block mt-4">3-day sequence - 9 leads - 100% Show-up - $5600 generated</span>
        </div>
        <div className="w-full md:w-1/3 text-center">
          <img src={pic1} alt="" className="mx-auto" />
          <span className="block mt-4">
            Here we generated 122 leads in 3 weeks, from which 38 attended the
            meetings, resulting in $647,000 generated in revenue
          </span>
        </div>
      </div>

      <div className="text-center py-12">
        <h1 className="text-3xl font-semibold text-gray-800">
          Why Hire an Individual Email Marketer When You Can Have A Whole Team Of Experts?
        </h1>
      </div>

      <div className="flex flex-wrap justify-center gap-10 px-4 mb-12">
        <div className="w-full md:w-1/3 p-6 border border-gray-200 rounded-lg text-center">
          <h2 className="text-2xl font-bold text-gray-800">$2000</h2>
          <h3 className="mt-2 font-semibold">Average Cost of an email marketer</h3>
          <p className="mt-4">
            They tend to charge higher rates, lack collaborative support, and
            face limitations due to time constraints.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-6 border border-gray-200 rounded-lg text-center">
          <h2 className="text-2xl font-bold text-red-500">-$4000</h2>
          <h3 className="mt-2 font-semibold">Dependency on one person</h3>
          <p className="mt-4">
            They pose the risk of a potential single point of failure, as their
            unavailability can disrupt your email marketing, leading to delays
            and inconsistent results.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-6 border border-gray-200 rounded-lg text-center">
          <h2 className="text-2xl font-bold text-gray-800">1 VS MANY</h2>
          <h3 className="mt-2 font-semibold">Outnumbered by Expertise</h3>
          <p className="mt-4">
            For less than the cost of an email marketer, you could gain a
            complete team of lead generation from Pen Stop CO.
          </p>
        </div>
      </div>

      <div className="text-center py-12">
        <h1 className="text-3xl font-semibold text-gray-800">Our Partners</h1>
      </div>

      <div className="flex flex-wrap justify-center gap-8 px-4">
        {[logo1, logo2, logo4, logo5, logo3, logo6].map((logo, index) => (
          <div key={index} className="w-1/2 sm:w-1/3 md:w-1/6 text-center">
            <img src={logo} alt="partner-logo" className="mx-auto h-24 object-contain" />
            <p className="mt-2">Partner {index + 1}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Result;
